import React, { useMemo, useState } from 'react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import './index.scss';
import { useAppContext } from '../../app/providers/useProvider';
import { Typography } from '../Typography';
import { ComponentTypes } from '../../types';
import { ROUTER_PATHS } from '../../app/types';

import { CloseIcon } from '../../assets/icons';

const mobileWidth = 261;
const tabletWidth = 372;

type MenuLayoutOutletContext = {
  handleMenuToggle: () => void;
};

export const MenuLayout = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();

  const {
    queryState: { isMobile },
  } = useAppContext();

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const modalWidth = useMemo(() => {
    return isMobile ? mobileWidth : tabletWidth;
  }, [isMobile]);

  const handleRedirectAndScroll = (id: string) => {
    const preparedId = id.split(' ').join('');

    navigate(`/#${preparedId}`);
  };

  return (
    <div id="outer-container">
      <Menu
        isOpen={isMenuOpen}
        onStateChange={({ isOpen }) => setMenuOpen(isOpen)}
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
        width={modalWidth}
        itemListClassName="menu-list"
        customCrossIcon={<CloseIcon />}
        crossButtonClassName="cross-button"
      >
        <Typography
          componentType={ComponentTypes.Title}
          className="menu-item"
          onClick={() => {
            handleRedirectAndScroll('Home');
            handleMenuToggle();
          }}
        >
          Home
        </Typography>
        <Typography
          componentType={ComponentTypes.Title}
          className="menu-item"
          onClick={() => {
            handleRedirectAndScroll('Features');
            handleMenuToggle();
          }}
        >
          Features
        </Typography>
        <Typography
          componentType={ComponentTypes.Title}
          className="menu-item"
          onClick={() => {
            handleRedirectAndScroll('Howitworks');
            handleMenuToggle();
          }}
        >
          How it works
        </Typography>
        <Typography
          componentType={ComponentTypes.Title}
          className="menu-item"
          onClick={() => {
            navigate(ROUTER_PATHS.privacy);
            handleMenuToggle();
          }}
        >
          Privacy Policy
        </Typography>
        <Typography
          componentType={ComponentTypes.Title}
          className="menu-item"
          onClick={() => {
            navigate(ROUTER_PATHS.termOfUse);
            handleMenuToggle();
          }}
        >
          Terms & Conditions
        </Typography>

        <div className="menu-email">info@myvita.ai</div>
      </Menu>

      <main id="page-wrap">
        <Outlet context={{ handleMenuToggle }} />
      </main>
    </div>
  );
};

export const useMenuLayoutContext = () => {
  return useOutletContext<MenuLayoutOutletContext>();
};
