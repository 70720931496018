import React, { useEffect, useMemo, useRef, useState } from 'react';

import styles from './index.module.scss';

import cn from 'classnames';
import { useAppContext } from '../../app/providers/useProvider';

const deviceHeight = window.innerHeight;
const mobileSmallHeightMin = 667;
const mobileSmallHeightMax = 740;

const mobileLargeHeightMin = 741;
const mobileLargeHeightMax = 960;

const getMobilePositionCoefficient = () => {
  if (deviceHeight <= mobileLargeHeightMax && deviceHeight >= mobileLargeHeightMin) {
    return 2.4;
  } else if (deviceHeight >= mobileSmallHeightMin && deviceHeight <= mobileSmallHeightMax) {
    return 3.1;
  }

  return 2;
};

const positionCoefficients = {
  desktop: 1.5,
  tablet: 1.4,
  mobile: getMobilePositionCoefficient(),
};

export const ProgressBar = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const {
    queryState: { isOnlyTablet, isMobile, isDesktop },
  } = useAppContext();

  const coefficientRef = useRef<number | null>(null);

  const startPositionCoefficient = useMemo(() => {
    if (isDesktop) {
      return positionCoefficients.desktop;
    }

    if (isOnlyTablet) {
      return positionCoefficients.tablet;
    }

    if (isMobile) {
      return positionCoefficients.mobile;
    }

    return 1.5;
  }, [isDesktop, isMobile, isOnlyTablet]);

  const pointsPosition = useMemo(() => {
    if (isMobile) {
      return {
        firstPoint: 45,
        secondPoint: 510,
        thirdPoint: 975,
        fourPoint: 1465,
        fivePoint: 1985,
        sixPoint: 2480,
        sevenPoint: 2777,
      };
    }

    return {
      firstPoint: '3%',
      secondPoint: '19.7%',
      thirdPoint: '36.4%',
      fourPoint: '53.1%',
      fivePoint: '69.6%',
      sixPoint: '86.5%',
    };
  }, [isMobile]);

  const breakpoints = isMobile
    ? {
        value1: 1.6,
        value2: 17,
        value3: 33,
        value4: 49.7,
        value5: 67.3,
        value6: 84.2,
        value7: 99,
      }
    : {
        value1: 3,
        value2: 19.7,
        value3: 36.4,
        value4: 53.1,
        value5: 69.6,
        value6: 86.5,
        value7: 88.2,
      };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    const docHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    if (coefficientRef.current) {
      const progressStartPosition = document.documentElement.clientHeight * coefficientRef.current;

      const scrollPercent = ((scrollTop - progressStartPosition) / (docHeight - progressStartPosition)) * 100;

      const normalizedScrollPercent = Math.max(1, Math.min(scrollPercent, 100));

      setScrollPosition(normalizedScrollPercent);
    }
  };

  useEffect(() => {
    coefficientRef.current = startPositionCoefficient;
  }, [startPositionCoefficient]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={styles.progressContainer}>
      <div className={styles.track}></div>

      <div className={styles.trackActive} style={{ height: `${scrollPosition}%` }} />

      <div className={styles.trackEnding}>
        <div className={styles.trackEndingInner} />
      </div>

      <div style={{ top: pointsPosition.firstPoint }} className={styles.thumbWrapper}>
        <div className={cn(styles.thumb, scrollPosition >= breakpoints.value1 && styles.thumbActive)}>
          <div
            className={cn(
              styles.thumbInnerCircle,
              scrollPosition >= breakpoints.value1 && styles.thumbInnerActive,
            )}
          />
        </div>
      </div>

      <div style={{ top: pointsPosition.secondPoint }} className={styles.thumbWrapper}>
        <div className={cn(styles.thumb, scrollPosition >= breakpoints.value2 && styles.thumbActive)}>
          <div
            className={cn(
              styles.thumbInnerCircle,
              scrollPosition >= breakpoints.value2 && styles.thumbInnerActive,
            )}
          />
        </div>
      </div>
      <div style={{ top: pointsPosition.thirdPoint }} className={styles.thumbWrapper}>
        <div className={cn(styles.thumb, scrollPosition >= breakpoints.value3 && styles.thumbActive)}>
          <div
            className={cn(
              styles.thumbInnerCircle,
              scrollPosition >= breakpoints.value3 && styles.thumbInnerActive,
            )}
          />
        </div>
      </div>

      <div style={{ top: pointsPosition.fourPoint }} className={styles.thumbWrapper}>
        <div className={cn(styles.thumb, scrollPosition >= breakpoints.value4 && styles.thumbActive)}>
          <div
            className={cn(
              styles.thumbInnerCircle,
              scrollPosition >= breakpoints.value4 && styles.thumbInnerActive,
            )}
          />
        </div>
      </div>

      <div style={{ top: pointsPosition.fivePoint }} className={styles.thumbWrapper}>
        <div className={cn(styles.thumb, scrollPosition >= breakpoints.value5 && styles.thumbActive)}>
          <div
            className={cn(
              styles.thumbInnerCircle,
              scrollPosition >= breakpoints.value5 && styles.thumbInnerActive,
            )}
          />
        </div>
      </div>

      <div style={{ top: pointsPosition.sixPoint }} className={styles.thumbWrapper}>
        <div className={cn(styles.thumb, scrollPosition >= breakpoints.value6 && styles.thumbActive)}>
          <div
            className={cn(
              styles.thumbInnerCircle,
              scrollPosition >= breakpoints.value6 && styles.thumbInnerActive,
            )}
          />
        </div>
      </div>
    </div>
  );
};
